<!-- Version: 1.0 -->
<template>
  <div>
    <b-modal
      style="border: solid 3px"
      centered
      hide-footer
      hide-header
      size="lg"
      v-model="isOpenModal"
    >
      <div
        :style="'background-color: ' + localBgColor + '; color: ' + localTextColor"
        class="confirm-noti-icon"
      >
        <slot><b-icon :icon="icon"></b-icon></slot>
      </div>
      <div style="text-align: center">
        <h2>
          <p style="padding: 15px 0px">{{ title }}</p>
        </h2>
        <p>{{ message }}</p>
      </div>
      <b-row align-h="center">
        <p
          @click="isOpenModal = false"
          class="confirm-button"
          style="background-color: #aaa; color: #fff"
        >
          {{ cancelButtonText }}
        </p>
        <p
          @click="confirmAccept();isOpenModal = false"
          class="confirm-button"
          :style="'background-color: ' + localBgColor + '; color: ' + localTextColor"
        >
          {{ acceptButtonText }}
        </p>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isOpen: false,
    };
  },
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    bgColor: String,
    textColor: String,
    icon: String,
    title: String,
    message: String,
    acceptButtonText: String,
    cancelButtonText: String,
    value: Boolean,
  },
  computed: {
    isOpenModal: {
      get() {
        return this.value;
      },
      set(input) {
        this.$emit("change", input);
      },
    },
    localBgColor(){
      return this.bgColor ? this.bgColor : "#222"
    },
    localTextColor(){
      return this.textColor ? this.textColor : "#fff"
    }
  },
  methods: {
    confirmAccept() {
      this.$emit("confirm-accept");
    },
  },
};
</script>
<style scoped>
.confirm-button {
  padding: 10px 15px;
  border-radius: 10px;
  cursor: pointer;
  margin: 10px 10px 20px;
  text-align:center;
}
.confirm-noti-icon {
  width: fit-content;
  font-size: 40px;
  border: solid 1px;
  border-radius: 100px;
  padding: 10px 20px;
  margin: -55px auto 0px;
}
</style>